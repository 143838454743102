import React from "react";
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto'
import { defaults } from 'chart.js/'
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

defaults.events = null

const BarChart = (props) => (
  <Bar
    height={350}
    width={870}
    data={{
      labels: ["Anger", "Disgust", "Fear", "Joy", "Sadness", "Surprised"],
      datasets: [{
        barThickness: 110,
        data: props.data,
        backgroundColor: [
          "rgba(255, 62, 62, 1)",
          "rgba(139, 234, 171, 1)",
          "rgba(150, 107, 219, 1)",
          "rgba(255, 164, 74, 1)",
          "rgba(146, 242, 247, 1)",
          "rgba(255, 239, 95, 1)",
        ],
      }]
    }}
    options={{
      plugins: {
        legend: { display: false },
        tooltip: { enabled: false },
        datalabels: {
          formatter: function (value, context) {
            return '%' + value
          },
          display: true,
          color: "white",
          font: { size: 50, family: "Montserrat", weight: "normal" },
          anchor: "end",
          offset: -70,
          align: "start"
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 80,
          bottom: 0
        }
      },
      responsive: false,
      maintainAspectRatio: true,
      resizeDelay: 10,
      scales: {
        x: {
          ticks: {
            font: {
              size: 24,
              family: "Montserrat",
            }
          }
        },
        y: {
          display: false
        }
      }
    }
    }
  />
);

export default BarChart;

