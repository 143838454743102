import axios from 'axios';
import './analyzeButton.css'
import { Spinner } from 'react-bootstrap'
import { inputText } from '../input/TextFieldInput.js'
import { keyText } from '../input/KeyFieldInput.js'
import { useState } from 'react';
import { Box } from '@mui/material'

// const getUrl = "https://basla-api-gateway-secure-2wegnm8.ew.gateway.dev/ekman-predict-single/"
// const postUrl = "https://basla-api-gateway-2wegnm8.ew.gateway.dev/uploadfile/"

// const getUrl = "https://basla-api-512-gateway-2wegnm8.ew.gateway.dev/ekman-predict-single/"
// const postUrl = "https://basla-api-512-gateway-2wegnm8.ew.gateway.dev/uploadfile/"

// const getUrl = "http://127.0.0.1/ekman-predict-single/"
// const postUrl = "http://127.0.0.1/uploadfile/"

const getUrl = "https://45pej8ol08.execute-api.eu-central-1.amazonaws.com/dev/ekman-predict-single/"
const postUrl = "https://45pej8ol08.execute-api.eu-central-1.amazonaws.com/dev/uploadfile/"

// ! API KEY -> QcWrK8heLiaNwAk7g2uQE1Waz4Ti2LG87tfI4qBR

async function clickHandler(props, setLoading) {
  // ! THIS PART SHOULD BE UNCOMMENTED FOR API KEY USAGE
  let keyInput = ""
  if (keyText !== "") {
    keyInput = keyText
  }
  // ! THIS PART SHOULD BE UNCOMMENTED FOR API KEY USAGE
  let textInput
  if (props.text !== '') {
    textInput = props.text
  }
  else {
    textInput = inputText
  }
  const textInputPretty = textInput.replaceAll("/", ",").replaceAll("?", ".")

  setLoading(true)
  props.setReqError(false)
  if (props.fileCheck === false) {
    const timeStartGet = performance.now();
    try {
      const resp = await axios.post(getUrl, textInputPretty,
        {
          headers:
          {
            'Content-Type': 'text/plain',
            'x-api-key': keyInput
          }
        });
      const timeEndGet = performance.now();
      const durationGet = ((timeEndGet - timeStartGet) / 1000).toFixed(2)

      var text = resp.data.replaceAll("'", "\"")
      var data = JSON.parse(text)
      var dataArr = []
      for (let i in data) {
        dataArr.push(parseFloat(data[i] * 100).toFixed(0));
      };

      props.setData(dataArr)
      props.setRenderChart(true)
      setLoading(false)
      props.setTime(durationGet)

      console.log("TRY");
      console.log(resp.data);

    } catch (error) {
      console.log("ERROR")
      console.log(error)
      console.log(error.response)
      props.setReqError(true)
      setLoading(false)
    }
  }
  else if (props.fileCheck === true) {
    const timeStartPost = performance.now();
    const formData = { file: props.textFile };
    axios.post(
      postUrl,
      formData,
      {
        headers:
        {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((response) => {
      const timeEndPost = performance.now();
      let durationPost = ((timeEndPost - timeStartPost) / 1000).toFixed(2)
      var text = response.data.replaceAll("'", "\"")
      var data = JSON.parse(text)
      // TODO - Implement overall stats with @ugurkoc
      // var dataArr = []
      // for (const emotion in data) {
      //   for (const i in emotion)
      //   dataArr.push(parseFloat(data[i] * 100).toFixed(0));
      // };
      props.setMultiData(data)
      props.setRenderTable(true)
      console.log(data)
      setLoading(false)
      props.setTime(durationPost)
    }).catch(function (error) {
      console.log("SOMETHING'S WRONG, PLEASE REFRESH THE PAGE AND CONTACT -- info@dert.solutions -- FOR MORE INFO");
      console.log(error);
      props.setReqError(true)
    })
  }
}

const AnalyzeButton = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Box boxSizing={10}></Box>
      < div >
        {
          loading ?
            <button className='loader'>
              <Spinner animation="grow" />
            </button> :
            <button className='analyze__button'
              onClick={() => { clickHandler(props, setLoading) }}>Analyze</button>
        }
      </div >
    </div>
  )
}
export default AnalyzeButton