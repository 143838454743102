import React, { useState } from 'react'
import { FileUploadOutlined } from '@mui/icons-material'
import { IconButton, Tooltip, tooltipClasses } from '@mui/material'
import './uploadButton.css'
import styled from '@emotion/styled'

const UploadButton = (props) => {
  const [fileName, setFileName] = useState('');

  const handleFileRead = (e) => {
    const content = fileReader.result;
    props.setText(content)
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#e6e6e6",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#fcfcfc",
      backgroundColor: "#0b0b0b",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "14px",
      border: "0.5px solid #fcfcfc",
      maxWidth: 350
    },
  }));

  let fileReader
  // eslint-disable-next-line no-unused-vars
  const handleFileChosen = (e) => {
    props.setTextFile(e);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(e);
    setFileName(e.name)
    props.setFileCheck(true)
    props.setRenderTable(false)
  }
  return (
    //! uncomment below to activate file upload
    <div>
      <BootstrapTooltip
        title={"Currently multi-input analysis is not available for public use. To book a demo, contact us at: info@dert.solutions"}>
        <div className='button__wrapper'>
          <IconButton disabled aria-label="upload picture" component="label" >
            {/* <input accept=".txt" type="file" hidden
              onChange={e => handleFileChosen(e.target.files[0])} /> */}
            <FileUploadOutlined className='icon__button' />
            {(fileName !== '') ?
              <div className='upload__button__info'>
                <label className='file__text' >{fileName}</label>
              </div>
              : <div></div>}
          </IconButton>
        </div>
      </BootstrapTooltip>
    </div>
  );
}

export default UploadButton
