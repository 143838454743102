import React from 'react'
import './textFieldInput.css'

var inputText = ""
export { inputText }

const TextFieldInput = (props) => {

  const handleInputChange = (e) => {
    inputText = (e.currentTarget.value);
  }

  return (
    <div className='input__body' >
      <h1 className='prompt__text'>Enter in your text or upload a file</h1>
      {props.fileCheck
        ? <textarea className='input__text__upd'
          value='Press "Analyze" to process your text file, refresh to type your own input again...'
          name='input-text'
          onChange={e => handleInputChange(e)}
          rows="6" wrap="soft" />
        : <textarea className='input__text'
          name='input-text'
          onChange={e => handleInputChange(e)}
          rows="6" wrap="soft" maxLength={550} />}
    </div>
  )
}

export default TextFieldInput