import React, { useEffect, useState } from "react";
import './commentsTable.css'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


const columns = [
  { id: 'text', label: 'Text', minWidth: 700 },
  { id: 'emotion', label: 'Emotion', minWidth: 100 }
];

function createData(text, emotion) {
  return { text, emotion };
}

const emoData = {
  0: { "Anger": "5264", "Disgust": "2135", "Joy": "9532" },
  1: { "Anger": "7302", "Disgust": "1036", "Joy": "8735" },
  2: { "Anger": "4213", "Disgust": "7412", "Joy": "4237" },
  3: { "Anger": "1246", "Disgust": "1234", "Joy": "2345" },
  4: { "Anger": "1246", "Disgust": "1234", "Joy": "2345" }
}

function emotionComparison(emoData, resultArr) {
  for (const key in emoData) {
    let maxVal = emoData[key].Anger
    let currKey = ""
    for (const keys in emoData[key]) {
      if (emoData[key][keys] > maxVal) {
        maxVal = emoData[key][keys]
        currKey = keys
      }
    }
    // console.log("TEST TEST TEST..")
    // console.log(emoData[key])
    // console.log(maxVal)
    resultArr.push(currKey)
  }
  return resultArr;
}

const CommentsTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const textArray = props.text.trim().replaceAll('\r', '').split('\n');
  let rows = Array.from(Array(textArray.length), () => new Array(2));

  //! EMO DATA YERINE YENI RESPONSU AL
  let resultArr = []
  emotionComparison(emoData, resultArr)
  useEffect(() => {
    props.setRenderChart(false)
  }, [props]);

  //! EMO DATA YERINE YENI RESPONSU AL

  if (textArray.length - 1 > 0) {
    for (var i = 0; i < textArray.length; i++) {
      rows[i] = createData(textArray[i], resultArr[i]);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // console.clear()
  return (
    <Paper sx={{ width: '70%', overflow: 'hidden', background: 'transparent', scrollbarColor: 'red' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ color: 'white', background: 'black', fontSize: '18px', fontFamily: 'Montserrat', }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.uniqueId}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell sx={{ color: 'white', background: 'black', fontSize: '16px', fontFamily: 'Montserrat' }}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx=
        {{
          fontFamily: 'Montserrat',
          '.MuiInputBase-root': {
            backgroundColor: 'rgb(30, 30, 30)',
            fontFamily: 'Montserrat'
          },
          '.MuiTablePagination-toolbar': {
            backgroundColor: 'black',
            color: 'white',
            fontFamily: 'Montserrat'
          },
          '.MuiTablePagination-selectLabel': {
            color: 'white',
            fontFamily: 'Montserrat'
          },
          '.MuiBox-root': {
            backgroundColor: 'black',
            color: 'black',
            fontFamily: 'Montserrat',

            '& .MuiSvgIcon-root': {
              backgroundColor: 'purple',
              color: 'black',
              fontFamily: 'Montserrat',
            },
          },
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default CommentsTable