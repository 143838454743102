import React from 'react'
import './keyFieldInput.css'

// TODO USE STATE FOR THIS
var keyText = ""
export { keyText }
// ! USE STATE FOR THIS

const KeyFieldInput = (props) => {

  const handleInputChange = (e) => {
    keyText = (e.currentTarget.value);
  }

  return (
    <div className='input__key__body' >
      <textarea
        className='key__text'
        placeholder='Enter your secure key...' 
        autoFocus
        wrap='off' rows="1" maxLength={150}
        onChange={e => handleInputChange(e)}
      />
    </div>
  )
}

export default KeyFieldInput