import React from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { Tooltip, tooltipClasses } from '@mui/material'
import './infoTooltip.css'
import styled from '@emotion/styled'

const InfoTooltip = (props) => {

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#e6e6e6",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#fcfcfc",
      backgroundColor: "#0b0b0b",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "14px",
      border: "0.5px solid #fcfcfc",
      maxWidth: 350
    },
  }));

  return (
    <div>
      <BootstrapTooltip
        title={
          <React.Fragment>
            {"Don't have a key? "}
            <u>{<a href='https://www.dert.solutions/basla-demo-request' target="_blank" rel="noopener noreferrer">Click here!</a>}</u>
          </React.Fragment>}>
        <div className='button__wrapper'>
          <InfoOutlined className='key__tooltip' />
        </div>
      </BootstrapTooltip>
    </div>
  );
}

export default InfoTooltip
