import React from 'react'
import { HelpOutline } from '@mui/icons-material'
import { Tooltip, tooltipClasses } from '@mui/material'
import './emotionTooltip.css'
import styled from '@emotion/styled'

const InfoTooltip = (props) => {

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#e6e6e6",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#fcfcfc",
      backgroundColor: "#0b0b0b",
      fontFamily: "Montserrat",
      fontWeight: "normal",
      fontSize: "16px",
      border: "0.5px solid #fcfcfc",
      maxWidth: 500
    },
  }));

  return (
    <BootstrapTooltip
      title={
        <React.Fragment>
          {"The values given on this graph represent the detected intensity of each emotion seperately . These values do not have to sum up to %100, and that is completely intentional. That is due to our model evaluating \"co-occurances\" of emotions instead of trying to detect a singular emotion to label the text. This allows us to model complex texts with conflicting emotional statements."}
        </React.Fragment>}>
      <div className='button__wrapper'>
        <HelpOutline className='emotion__icon' />
      </div>
    </BootstrapTooltip>
  );
}

export default InfoTooltip
