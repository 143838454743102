import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  AnalyzeButton,
  BarChart,
  TextFieldInput,
  UploadButton,
  CommentsTable,
  InfoTooltip,
  KeyFieldInput,
  EmotionTooltip,
} from './components'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './App.css'
import './index.css'

// import { initializeApp } from "firebase/app";
// const firebaseConfig = {
//   apiKey: "AIzaSyCpmbAU8zLtSSbW4NoujnaSUyjztL2Addk",
//   authDomain: "basla-frontend.firebaseapp.com",
//   projectId: "basla-frontend",
//   storageBucket: "basla-frontend.appspot.com",
//   messagingSenderId: "808426113180",
//   appId: "1:808426113180:web:9cfc6524aac268ce008559"
// };
// const app = initializeApp(firebaseConfig);

const App = () => {
  const [text, setText] = useState('');
  const [data, setData] = useState([]);
  const [time, setTime] = useState(0);
  const [reqError, setReqError] = useState(false);
  const [multiData, setMultiData] = useState({});
  const [textFile, setTextFile] = useState();
  const [fileCheck, setFileCheck] = useState(false);
  const [renderChart, setRenderChart] = useState(false);
  const [renderTable, setRenderTable] = useState(false);

  const maintenance = false
  return (
    <div className='App'>
      <div className='logo__row__first'>
        <img className='basla_logo' src={require("./assets/basla_logo_text.png")}
          alt="dert logo" />
      </div>
      <div className='logo__row__second'>
        <a href='https://www.dert.solutions/' target="_blank" rel="noopener noreferrer">
          <img className='dert_logo' src={require("./assets/by_dert.png")}
            alt="dert logo" />
        </a>
      </div>
      {maintenance
        ?
        <div className='maintenance__container'>
          <img className='maintenance__img' src={require("./assets/basla_bg.png")} alt="basla bg" />
          <div class='maintenance__text'>Currently migrating our stuff, we'll be back in no time.</div>
        </div>
        :
        <Container className='main__container'>

          {/* <Row className='welcome__row'>
          <Col ></Col>
          <Col md={8} >
            <p className='welcome__text'>
              Welcome to<span style={{ fontWeight: 'bold' }}> BASLA</span>
            </p>
          </Col>
          <Col ></Col>
        </Row> */}

          <Row className='textfield__row'>
            <Col ></Col>
            <Col md={8} >
              <TextFieldInput fileCheck={fileCheck} />
              <Row className='key__row'>
                <Col className='key__col'>
                  <KeyFieldInput></KeyFieldInput>
                </Col>
                <Col className='col__test' xs="auto">
                  <InfoTooltip></InfoTooltip>
                </Col>
              </Row>
            </Col>
            <Col ></Col>
          </Row>

          <Row style={{ alignItems: 'center' }} className='buttons__row'>
            <Col xs={1}></Col>
            <Col xs={1}></Col>
            <Col xs={4}>
              <UploadButton
                setText={setText}
                setTextFile={setTextFile}
                setFileCheck={setFileCheck}
                setRenderTable={setRenderTable} />
            </Col>
            <Col ></Col>
            <Col ></Col>
            <Col ></Col>
            <Col ></Col>
            <Col ></Col>
            <Col >
              <AnalyzeButton
                setData={setData}
                text={text}
                setRenderChart={setRenderChart}
                setRenderTable={setRenderTable}
                setTime={setTime}
                setMultiData={setMultiData}
                fileCheck={fileCheck}
                textFile={textFile}
                setReqError={setReqError} />
            </Col>
            {time !== 0 ?
              <Col xs={1} style={{ color: 'white', fontSize: '11px', fontFamily: 'Montserrat', justifyContent: 'center', padding: '0px' }}>
                {time} sec
              </Col>
              : <Col xs={1}></Col>}
            <Col xs={1}></Col>
          </Row>
          {reqError ?
            <h1 className='error__msg'>Something's wrong, please try again..</h1> :
            <div></div>
          }
          {renderChart ?
            <Row className='chart__row'>
              <Col></Col>
              <Col md="auto">
                <BarChart data={data} />
                <Row className='chart__info__row'>
                  <Col xs="auto">
                    <p className='chart__info__text'>Emotional Intensities</p>
                  </Col>
                  <Col xs="auto">
                    <EmotionTooltip />
                  </Col>
                </Row>
              </Col>
              <Col></Col>
            </Row> :
            <div></div>
          }
          {renderTable ?
            <Row className='table__row'>
              <Col></Col>
              <CommentsTable
                key="comments__table"
                setRenderChart={setRenderChart}
                multiData={multiData}
                text={text}
              />
              <Col></Col>
            </Row> :
            <div></div>
          }
        </Container>}
    </div>
  )
}

export default App